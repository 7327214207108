import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Cookies from 'js-cookie'
import { useLocation } from 'react-router-dom'
import moment from 'moment-timezone'

import { connectResource } from '~/common/utils/resource'
import { getCookie } from '~/lib/utils'
import { setData } from '~/common/utils/resource'
import { getDomain, getCookieDomain } from '~/common/utils/helpers'
import { logout } from '~/common/session'
import { getInit } from '~/services/init'
import { reset as intercomReset, update as intercomUpdate } from '~/common/utils/intercom'
import { reset as posthogReset, identify } from '~/common/utils/posthog'
import { parseQueryParams } from '~/common/utils/queryParams'
import { setUser } from '~/common/utils/sentry'

export class Authenticate extends Component {
  getUsersProfile = async (skipIntercom = false) => {
    const [user, profile] = await Promise.all([this.props.user.fetch(), this.props.profile.fetch()])
    if (
      !user ||
      (user && user instanceof Error) ||
      !profile ||
      (profile && profile instanceof Error) ||
      !getCookie('jwt')
    ) {
      Cookies.remove('jwt', { domain: getDomain().replace('app', '') })
      this.props.logout(true)
      if (!skipIntercom) {
        setUser()
        intercomReset()
        posthogReset()
      }
    } else {
      this.props.setData(
        {
          token: getCookie('jwt'),
          partnerToken: getCookie('partner_jwt'),
        },
        { resource: { namespace: 'session' } }
      )
      const queryParams = parseQueryParams(this.props.location.search)
      if (queryParams && !!queryParams.logged_in) {
        Cookies.set('logged_in', true, { domain: getCookieDomain() })
        Cookies.set('gan-logged_in', true, {
          domain: getDomain(null).replace('app', ''),
        })
        if (!skipIntercom) {
          setUser()
          intercomReset()
          posthogReset()
        }
      } else {
        if (Cookies.get('gan-logged_in')) {
          if (!skipIntercom) {
            setUser()
            intercomReset()
            posthogReset()
          }
        } else {
          if (!skipIntercom) {
            setUser(user)
            intercomUpdate(user)
            identify(user, profile)
          }
        }
      }

      if (profile && profile.timezone) {
        moment.tz.setDefault(profile.timezone)
      }
    }
  }

  getPartnerProfile = async () => {
    const partnerProfile = await this.props.partnerProfile.fetch()
    if (
      !partnerProfile ||
      (partnerProfile && partnerProfile instanceof Error) ||
      !getCookie('partner_jwt')
    ) {
      Cookies.remove('jwt', { domain: getDomain().replace('app', '') })
      Cookies.remove('partner_jwt', { domain: getDomain().replace('app', '') })
      this.props.logout()
      setUser()
      intercomReset()
      posthogReset()
    } else {
      this.props.setData(
        {
          token: getCookie('jwt'),
          partnerToken: getCookie('partner_jwt'),
        },
        { resource: { namespace: 'session' } }
      )
      const queryParams = parseQueryParams(this.props.location.search)
      if (queryParams && !!queryParams.logged_in) {
        Cookies.set('logged_in', true, { domain: getCookieDomain() })
        Cookies.set('gan-logged_in', true, {
          domain: getDomain(null).replace('app', ''),
        })
        setUser()
        intercomReset()
        posthogReset()
      } else {
        if (Cookies.get('gan-logged_in')) {
          setUser()
          intercomReset()
          posthogReset()
        } else {
          setUser(partnerProfile, true)
          intercomUpdate(partnerProfile, true)
        }
      }

      if (partnerProfile && partnerProfile.timezone) {
        moment.tz.setDefault(partnerProfile.timezone)
      }
    }
  }

  async componentDidMount() {
    await getInit()
    this.props.setData(
      {
        token: getCookie('jwt'),
        partnerToken: getCookie('partner_jwt'),
      },
      { resource: { namespace: 'session' } }
    )
    if (getCookie('jwt') && !getCookie('partner_jwt')) {
      await this.getUsersProfile()
    } else if (getCookie('partner_jwt')) {
      await this.getPartnerProfile()
      if (getCookie('jwt')) {
        await this.getUsersProfile(true)
      }
    } else {
      this.props.logout()
      setUser()
      intercomReset()
      posthogReset()
    }
    Cookies.set('isBeta', true, { domain: getDomain(null) })
  }

  componentWillUnmount() {
    this.props.partnerProfile.setData(null)
    this.props.profile.setData(null)
    this.props.user.setData(null)
    setUser()
    if (window.Intercom && window.INTERCOME_APP_ID) {
      intercomReset()
    }
    posthogReset()
  }

  render() {
    return this.props.children
  }
}

const ComposedContainer = compose(
  connectResource({
    namespace: 'partnerProfile',
    endpoint: 'partner/profile',
    async: true,
    prefetch: false,
  }),
  connectResource({
    namespace: 'profile',
    endpoint: 'profile',
    async: true,
    prefetch: false,
  }),
  connectResource({
    namespace: 'user',
    endpoint: 'user',
    async: true,
    prefetch: false,
  }),
  connect(null, { setData, logout })
)(Authenticate)

function ComposedContainerWrapper(props) {
  const location = useLocation()
  return <ComposedContainer {...props} location={location} />
}

export default ComposedContainerWrapper
