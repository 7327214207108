import React, { Fragment, useEffect, useMemo } from 'react'
import {
  AddressBook,
  ArrowSquareOut,
  At,
  ChartBar,
  ClipboardText,
  Compass,
  Envelope,
  HouseLine,
  Info,
  Layout,
  Lifebuoy,
  Lock,
  Scroll,
  Stack,
  Tag,
  UsersThree,
  ChatCircleText,
  PhoneOutgoing,
  GraduationCap,
} from 'phosphor-react'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { FormattedMessage } from 'react-intl'
import { Menu, Transition } from '@headlessui/react'

import { classNames } from '~/common/utils/classNames'
import { getCurrentTrial } from '~/common/utils/getCurrentTrial'
import { connectResource } from '~/common/utils/resource'
import { IMPORT_PLAN_LEVEL_NAMES_LONG, PLANS_LEVELS } from '~/common/utils/constants/providers'
import NavLinkExact from '~/components/NavLinkExact'
import { API_VERSION_V4 } from '~/api.jsx'
import {
  defaultPagination,
  SMS_SENDER_STATUS_APPROVED,
  SMS_SENDER_STATUS_PENDING,
} from '~/common/utils/constants/index.jsx'
import { captureFilteredExceptionHandler } from '~/common/utils/sentry/index.js'

export const AppSidebar = ({ profile, smsSenders, smsDrafts, smsSent }) => {
  const [currentTrial, setCurrentTrial] = React.useState(false)

  React.useEffect(() => {
    const currentTrial = getCurrentTrial(profile?.data?.trial)
    setCurrentTrial(currentTrial)
  }, [profile?.data?.trial])

  useEffect(() => {
    const fetch = async () => {
      try {
        await smsDrafts.fetch()
        await smsSent.fetch()
        await smsSenders.fetch()
      } catch (e) {
        captureFilteredExceptionHandler(e)
      }
    }
    fetch()
  }, [])

  React.useEffect(() => {
    if (!smsSenders?.data) {
      smsSenders?.fetch()
    }
  }, [smsSenders?.data])

  const planName = IMPORT_PLAN_LEVEL_NAMES_LONG[profile?.data?.subscription?.plan_level]
  const isFreeSubscription = profile?.data?.subscription?.is_free
  const isEducationEnabled = !!profile?.data?.features?.EDUCATION

  const isSenderApproved = useMemo(() => {
    return smsSenders?.data?.some((sender) => sender.status === SMS_SENDER_STATUS_APPROVED)
  }, [smsSenders?.data])

  const isSenderPending = useMemo(() => {
    return smsSenders?.data?.some((sender) => sender.status === SMS_SENDER_STATUS_PENDING)
  }, [smsSenders?.data])
  const shouldRenderDrafts = useMemo(() => smsDrafts?.data?.length > 0, [smsDrafts?.data])
  const shouldRenderSent = useMemo(() => smsSent?.data?.length > 0, [smsSent?.data])
  const isEmpty = useMemo(() => !shouldRenderDrafts && !shouldRenderSent, [
    shouldRenderDrafts,
    shouldRenderSent,
  ])

  return (
    <nav className="sticky top-[60px] hidden h-[calc(100vh-60px)] w-64 min-w-[250px] flex-col overflow-y-scroll border-r bg-gray-100 px-5 py-4 lg:flex">
      <div className="flex-1">
        <div className="mt-1 flex-1 space-y-1">
          <CustomNavLink to="/" exact>
            <HouseLine weight="duotone" size={20} />
            <FormattedMessage id="title.home" />
          </CustomNavLink>
          {isEducationEnabled && (
            <CustomNavLink to="/education" exact>
              <GraduationCap weight="duotone" size={20} />
              <FormattedMessage id="title.education" />
              <span className="ml-auto rounded-full border border-green-500 px-1.5 text-[11px] font-semibold text-green-600">
                <FormattedMessage id="common.new" />
              </span>
            </CustomNavLink>
          )}
        </div>

        <div className="mt-5 px-3 text-xs font-medium uppercase tracking-wider text-gray-500">
          <FormattedMessage id="sidebar.header.manage" />
        </div>

        <div className="mt-3 flex-1 space-y-0.5">
          <CustomNavLink to="/contacts">
            <AddressBook weight="duotone" size={20} />
            <FormattedMessage id="common.titles.contacts" />
          </CustomNavLink>
          <CustomNavLink to="/subscriptions">
            <UsersThree weight="duotone" size={20} />
            <FormattedMessage id="title.subscriptions" />
          </CustomNavLink>
          <CustomNavLink to="/lists">
            <ClipboardText weight="duotone" size={20} />
            <FormattedMessage id="title.lists" />
          </CustomNavLink>
          <CustomNavLink to="/attributes">
            <Tag weight="duotone" size={20} />
            <FormattedMessage id="title.attributes" />
          </CustomNavLink>
        </div>

        <div className="mt-5 px-3 text-xs font-medium uppercase tracking-wider text-gray-500">
          <FormattedMessage id="sidebar.header.grow" />
        </div>

        <div className="mt-3 flex-1 space-y-0.5">
          <CustomNavLink to="/forms">
            <Stack weight="duotone" size={20} />
            <FormattedMessage id="title.forms" />
          </CustomNavLink>
          <CustomNavLink to="/pages">
            <Layout weight="duotone" size={20} />
            <FormattedMessage id="common.titles.pages" />
          </CustomNavLink>
          <CustomNavLink to="/surveys">
            <Compass weight="duotone" size={20} />
            <FormattedMessage id="title.surveys" />
          </CustomNavLink>
        </div>

        <div className="mt-5 px-3 text-xs font-medium uppercase tracking-wider text-gray-500">
          <FormattedMessage id="sidebar.header.newsletters" />
        </div>

        <div className="mt-3 flex-1 space-y-1">
          <CustomNavLink to="/mails">
            <Envelope weight="duotone" size={20} />
            <FormattedMessage id="common.mails" />
          </CustomNavLink>
          <CustomNavLink to="/reports">
            <ChartBar weight="duotone" size={20} />
            <FormattedMessage id="title.reports" />
          </CustomNavLink>
          <CustomNavLink to="/account/senders">
            <At weight="duotone" size={20} />
            <FormattedMessage id="appNavBar.profileDropDown.senders" />
          </CustomNavLink>
        </div>

        <>
          <div className="mt-5 flex justify-between px-3 text-xs font-medium uppercase tracking-wider text-gray-500">
            SMS
            <span className="ml-1 rounded-full border border-green-500 px-1.5 text-[11px] font-semibold text-green-600">
              <FormattedMessage id="common.new" />
            </span>
          </div>
          <div className="mt-3 flex-1 space-y-1">
            <CustomNavLink to="/sms" exact>
              <ChatCircleText weight="duotone" size={20} />
              SMS
            </CustomNavLink>
            {(isSenderApproved || (!isEmpty && isSenderPending)) && (
              <CustomNavLink to="/sms/senders">
                <PhoneOutgoing weight="duotone" size={20} />
                <FormattedMessage id="appNavBar.profileDropDown.senders" />
              </CustomNavLink>
            )}
          </div>
        </>
      </div>

      <div className="mt-3 space-y-0.5">
        {profile?.data?.subscription && !currentTrial && (
          <Link
            data-test="upgrade-link"
            className="flex items-center justify-between rounded-lg border border-transparent px-3 py-2 text-xs hover:bg-gray-200 hover:bg-opacity-60"
            to={`/upgrade${
              profile.data.subscription.plan_level > PLANS_LEVELS.START
                ? `?planLevel=${profile.data.subscription.plan_level}`
                : ''
            }`}
          >
            <span className="font-[450] text-gray-800">
              <FormattedMessage id={isFreeSubscription ? 'plan.name.free.long' : planName} />
            </span>
            <span className="font-[450] text-blue-600">
              <FormattedMessage id="common.upgrade" /> →
            </span>
          </Link>
        )}
        {!!currentTrial && (
          <Link
            data-test="trial-upgrade-link"
            className="flex flex-col gap-1 rounded-lg border border-transparent px-3 py-2 text-xs hover:bg-gray-200 hover:bg-opacity-60"
            to={`/upgrade${
              currentTrial?.plan_level ? `?planLevel=${currentTrial?.plan_level}` : ''
            }`}
          >
            <div className="relative font-[450] text-gray-800">
              <span className="absolute top-0.5 mt-px flex h-2.5 w-2.5 items-center justify-center">
                <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-green-500 opacity-75"></span>
                <span className="relative inline-flex h-2 w-2 rounded-full bg-green-600"></span>
              </span>
              <span className="ml-4">
                <FormattedMessage id="sidebar.trial.in-progress" />
              </span>
            </div>
            <div className="flex justify-between">
              <span>
                <FormattedMessage
                  id="sidebar.trial.time-left"
                  values={{ days: currentTrial?.days_left || 0 }}
                />
              </span>
              <span className="font-[450] text-blue-600">
                <FormattedMessage id="common.upgrade" /> →
              </span>
            </div>
          </Link>
        )}
        <div>
          <HelpSupportMenu />
        </div>
      </div>
    </nav>
  )
}

function CustomNavLink({ to, children, ...restProps }) {
  const prepareClassName = React.useCallback(({ isActive }) => {
    return [
      'flex items-center gap-2 rounded-lg border border-transparent py-1.5 px-2.5 font-[450]',
      isActive
        ? 'bg-gray-200 text-blue-600'
        : 'text-gray-900 hover:bg-gray-200 hover:bg-opacity-60 hover:text-black',
    ]
      .filter(Boolean)
      .join(' ')
  }, [])
  return (
    <NavLinkExact to={to} className={prepareClassName} {...restProps}>
      {children}
    </NavLinkExact>
  )
}

export const HelpSupportMenu = () => {
  return (
    <Menu as="div" className="relative text-left">
      <div>
        <Menu.Button className="flex w-full items-center justify-between gap-2.5 rounded-lg border border-transparent px-3 py-2 text-left text-gray-600 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2 focus:ring-offset-gray-100">
          <div className="flex items-center gap-2.5">
            <div className="text-xs font-[450] text-gray-600">
              <FormattedMessage id="sidebar.helpSupport" />
            </div>
          </div>
          <svg
            fill="none"
            height="13"
            stroke="currentColor"
            strokeWidth="1.5"
            viewBox="0 0 16 24"
            className=""
          >
            <path d="M13 8.517L8 3 3 8.517M3 15.48l5 5.517 5-5.517"></path>
          </svg>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-1 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          unmount={false}
          className="absolute bottom-full left-0 mb-2 w-[215px] origin-bottom divide-y divide-gray-100 rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div className="p-2">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="https://support.getanewsletter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex items-center justify-between rounded-md py-1.5 px-2 hover:bg-gray-100'
                  )}`}
                >
                  <div className="flex items-center gap-2">
                    <Lifebuoy weight="duotone" size={16} />
                    <FormattedMessage id="contactus.helpcenter.title" />
                  </div>
                  <ArrowSquareOut className="-mt-px" weight="fill" />
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/contact"
                  className={`${classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex items-center gap-2 rounded-md  py-1.5 px-2 hover:bg-gray-100'
                  )}`}
                >
                  <Info weight="duotone" size={16} />
                  <FormattedMessage id="title.contactus" />
                </Link>
              )}
            </Menu.Item>
          </div>
          <div className="p-2">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/legal/privacy"
                  className={`${classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex items-center gap-2 rounded-md  py-1.5 px-2 hover:bg-gray-100'
                  )}`}
                >
                  <Lock weight="duotone" size={16} />
                  <FormattedMessage id="footer.policy" />
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/legal/terms"
                  className={`${classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex items-center gap-2 rounded-md  py-1.5 px-2 hover:bg-gray-100'
                  )}`}
                >
                  <Scroll weight="duotone" size={16} />
                  <FormattedMessage id="footer.terms" />
                </Link>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default compose(
  connectResource({
    namespace: 'profile',
    endpoint: 'profile',
    async: true,
    prefetch: false,
  }),
  connectResource({
    namespace: 'smsSenders',
    endpoint: 'sms/senders/:id?',
    apiVersion: API_VERSION_V4,
    list: true,
    async: true,
    idKey: 'id',
    prefetch: false,
  }),
  connectResource({
    namespace: 'smsDrafts',
    endpoint: 'sms/messages/draft',
    list: true,
    async: true,
    prefetch: false,
    apiVersion: API_VERSION_V4,
    filters: { paginate_by: defaultPagination },
  }),
  connectResource({
    namespace: 'smsSent',
    endpoint: 'sms/messages/sent',
    list: true,
    async: true,
    prefetch: false,
    apiVersion: API_VERSION_V4,
    filters: { paginate_by: defaultPagination },
  })
)(AppSidebar)
